














import AbstractField from '@/shared/classes/form/fields/abstract-field'
import { Component } from 'vue-property-decorator'

@Component
export default class SwitchField extends AbstractField {}
